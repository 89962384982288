import React, { useState, useEffect, useContext } from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import Fade from 'react-reveal/Fade'
import { useScrollPosition } from '@n8tb1t/use-scroll-position'
import { Row, Col } from 'react-simple-flex-grid'
import classNames from 'classnames'
import Img from 'gatsby-image'

import { AppContext } from '_context'
import { Layout } from '_templates'
import { Container, Image, ScrollArrow, Heading, Text } from '_atoms'
import { Quote, ProudCases } from '_molecules'
import { SEO, ViewportObserver } from '_organisms'
import SULogo from '_images/svgs/su-logo-colored.inline.svg'
import variables from '_config/css-variables'

import styles from './styles.module.css'

const SUCaseStudy = ({ data }) => {
  const suCaseStudy = data.contentfulSuCaseStudy

  const { setNavbarColor, setNavbarLight } = useContext(AppContext)

  const [y, setY] = useState(0)

  useScrollPosition(
    ({ currPos }) => {
      setY(currPos.y * -1)
    },
    [],
    null,
    false,
    300
  )

  useEffect(() => {
    setNavbarColor(variables['su-primary'])
    setNavbarLight(false)
  }, [setNavbarColor, setNavbarLight])

  return (
    <Layout navbarColorOverride={variables['su-primary']}>
      <SEO
        title={suCaseStudy.pageTitle || 'Web &amp; Mobile App Design and Development Company'}
        description={
          suCaseStudy.pageDescription || 'Web &amp; Mobile App Design and Development Company'
        }
        thumbnail={suCaseStudy.pageThumbnail.file.url}
      />

      <main className={styles.hero}>
        <Container>
          <div className={styles.heroImageWrapper}>
            <Fade distance="10%" bottom>
              <SULogo />
            </Fade>

            <Fade distance="10%" delay={200} bottom>
              <Img
                fluid={suCaseStudy.heroImage.fluid}
                alt={suCaseStudy.heroImage.description}
                className={styles.heroImageWrapperImage}
                fadeIn={false}
              />
            </Fade>
          </div>
        </Container>

        <Fade distance="10%" delay={200} bottom>
          <ScrollArrow className={classNames(styles.arrowButton)} />
        </Fade>

        <ViewportObserver
          scroll={y}
          onViewport={() => {
            setNavbarColor(variables['su-primary'])
            setNavbarLight(false)
          }}
        />
      </main>

      <section className={styles.brief}>
        <div className={styles.briefContentWrapper}>
          <Fade distance="10%" delay={350} bottom>
            <div className={classNames(styles.stickyImageWrapper, styles.briefImageWrapper)}>
              <Image {...suCaseStudy.briefImage.fluid} alt={suCaseStudy.briefImage.description} />
            </div>
          </Fade>

          <Container>
            <div className={styles.briefTextWrapper}>
              <Fade distance="10%" bottom>
                <Heading
                  type="h1"
                  color="white"
                  bold
                  className={classNames(styles.heading, styles.briefHeading)}
                >
                  {suCaseStudy.briefTitle}
                  <Text color="suPink" className={styles.dot}>
                    .
                  </Text>
                </Heading>
              </Fade>

              <Fade distance="10%" delay={200} bottom>
                <Text
                  color="white"
                  className={classNames(styles.description, styles.briefDescription)}
                  splitParagraphs
                >
                  {suCaseStudy.briefDescription.internal.content}
                </Text>
              </Fade>
            </div>
          </Container>
        </div>

        <ViewportObserver
          scroll={y}
          onViewport={() => {
            setNavbarColor(variables['su-gray'])
            setNavbarLight(false)
          }}
        />
      </section>

      <section className={styles.ideation}>
        <Container>
          <Fade distance="10%" bottom>
            <Text className={styles.description} color="white" splitParagraphs>
              {suCaseStudy.ideationDescription.internal.content}
            </Text>
          </Fade>
        </Container>

        <ViewportObserver
          scroll={y}
          onViewport={() => {
            setNavbarColor(variables['su-pink'])
            setNavbarLight(false)
          }}
        />
      </section>

      <section className={styles.singleSignOn}>
        <div className={styles.singleSignOnContentWrapper}>
          <Fade distance="10%" delay={400} bottom>
            <div className={classNames(styles.stickyImageWrapper, styles.singleSignOnImageWrapper)}>
              <Image
                {...suCaseStudy.singleSignOnImage.fluid}
                alt={suCaseStudy.singleSignOnImage.description}
              />
            </div>
          </Fade>

          <Container>
            <div className={styles.singleSignOnTextWrapper}>
              <Fade distance="10%" delay={200} bottom>
                <Heading
                  type="h4"
                  color="white"
                  bold
                  className={classNames(styles.heading, styles.singleSignOnHeading)}
                >
                  {suCaseStudy.singleSignOnTitle}
                  <Text color="suGray" className={styles.dot}>
                    .
                  </Text>
                </Heading>
              </Fade>

              <Fade distance="10%" delay={350} bottom>
                <Text
                  color="white"
                  className={classNames(styles.description, styles.singleSignOnDescription)}
                  splitParagraphs
                >
                  {suCaseStudy.singleSignOnDescription.internal.content}
                </Text>
              </Fade>
            </div>
          </Container>
        </div>

        <ViewportObserver
          scroll={y}
          onViewport={() => {
            setNavbarColor(variables['su-blue'])
            setNavbarLight(false)
          }}
        />
      </section>

      <section className={styles.personalizedFeed}>
        <div className={styles.personalizedFeedContentWrapper}>
          <Fade distance="10%" delay={350} bottom>
            <div
              className={classNames(styles.stickyImageWrapper, styles.personalizedFeedImageWrapper)}
            >
              <Image
                {...suCaseStudy.personalizedFeedImage.fluid}
                alt={suCaseStudy.personalizedFeedImage.description}
              />
            </div>
          </Fade>

          <Container>
            <div className={styles.personalizedFeedTextWrapper}>
              <Fade distance="10%" delay={200} bottom>
                <Heading type="h4" color="default" bold className={styles.heading}>
                  {suCaseStudy.personalizedFeedTitle}
                  <Text color="suPink" className={styles.dot}>
                    .
                  </Text>
                </Heading>
              </Fade>

              <Fade distance="10%" delay={300} bottom>
                <Text color="dark" className={styles.description} splitParagraphs>
                  {suCaseStudy.personalizedFeedDescription.internal.content}
                </Text>
              </Fade>
            </div>
          </Container>
        </div>

        <ViewportObserver
          scroll={y}
          onViewport={() => {
            setNavbarColor(variables['color-white'])
            setNavbarLight(true)
          }}
        />
      </section>

      <section className={styles.inAppChat}>
        <Container>
          <Row className={styles.inAppChatSectionRow}>
            <Col xs={12} sm={12} md={6} lg={6} className={styles.inAppChatImageWrapper}>
              <Fade distance="10%" delay={200} bottom>
                <Image
                  {...suCaseStudy.inAppChatImage.fluid}
                  alt={suCaseStudy.inAppChatImage.description}
                />
              </Fade>
            </Col>

            <Col xs={12} sm={12} md={6} lg={6} className={styles.inAppChatTextWrapper}>
              <Fade distance="10%" delay={350} bottom>
                <Heading
                  type="h4"
                  color="white"
                  bold
                  className={classNames(styles.heading, styles.inAppChatHeading)}
                >
                  {suCaseStudy.inAppChatTitle}
                  <Text color="suPink" className={styles.dot}>
                    .
                  </Text>
                </Heading>
              </Fade>
              <Fade distance="10%" delay={400} bottom>
                <Text
                  className={classNames(styles.description, styles.inAppChatDescription)}
                  color="white"
                  splitParagraphs
                >
                  {suCaseStudy.inAppChatDescription.internal.content}
                </Text>
              </Fade>
            </Col>
          </Row>
        </Container>

        <ViewportObserver
          scroll={y}
          onViewport={() => {
            setNavbarColor(variables['su-primary'])
            setNavbarLight(false)
          }}
        />
      </section>

      <section className={styles.scalableSearch}>
        <Container>
          <Row>
            <Col xs={12} sm={12} md={6} lg={6} className={styles.scalableSearchTextWrapper}>
              <Fade distance="10%" bottom>
                <Heading
                  type="h4"
                  color="white"
                  bold
                  className={classNames(styles.heading, styles.scalableSearchHeading)}
                >
                  {suCaseStudy.scalableSearchTitle}
                  <Text color="suGray" className={styles.dot}>
                    .
                  </Text>
                </Heading>
              </Fade>

              <Fade distance="10%" delay={200} bottom>
                <Text
                  color="white"
                  className={classNames(styles.description, styles.scalableSearchDescription)}
                  splitParagraphs
                >
                  {suCaseStudy.scalableSearchDescription.internal.content}
                </Text>
              </Fade>
            </Col>
            <Col xs={12} sm={12} md={6} lg={6} className={styles.scalableSearchImagesWrapper}>
              {suCaseStudy.scalableSearchImages.map((image, index) => (
                <Fade distance="10%" key={image.fluid.src} delay={index * 200} bottom>
                  <Image {...image.fluid} alt={image.description} />
                </Fade>
              ))}
            </Col>
          </Row>

          <ViewportObserver
            scroll={y}
            onViewport={() => {
              setNavbarColor(variables['su-orange'])
              setNavbarLight(false)
            }}
          />
        </Container>
      </section>

      <section className={styles.smartNotifications}>
        <Container>
          <Row className={styles.smartNotificationsContentWrapper}>
            <Col xs={12} sm={12} md={6} lg={6} className={styles.smartNotificationsImageWrapper}>
              <Fade distance="10%" bottom>
                <Image
                  className={styles.smartNotificationsDesktopImage}
                  {...suCaseStudy.smartNotificationsDesktopImage.fluid}
                  alt={suCaseStudy.smartNotificationsDesktopImage.description}
                />
                <Image
                  className={styles.smartNotificationsMobileImage}
                  {...suCaseStudy.smartNotificationsMobileImage.fluid}
                  alt={suCaseStudy.smartNotificationsMobileImage.description}
                />
              </Fade>
            </Col>

            <Col xs={12} sm={12} md={6} lg={6} className={styles.smartNotificationsTextWrapper}>
              <Fade distance="10%" delay={200} bottom>
                <Heading type="h4" color="default" bold className={styles.heading}>
                  {suCaseStudy.smartNotificationsTitle}
                  <Text color="suPink" className={styles.dot}>
                    .
                  </Text>
                </Heading>
              </Fade>
              <Fade distance="10%" delay={300} bottom>
                <Text className={styles.description} color="dark" splitParagraphs>
                  {suCaseStudy.smartNotificationsDescription.internal.content}
                </Text>
              </Fade>
            </Col>
          </Row>
        </Container>

        <ViewportObserver
          scroll={y}
          onViewport={() => {
            setNavbarColor(variables['color-white'])
            setNavbarLight(true)
          }}
        />
      </section>

      <section className={styles.dataManagement}>
        <Container>
          <Row className={styles.dataManagementContentWrapper}>
            <Col xs={12} sm={12} md={6} lg={6} className={styles.dataManagementTextWrapper}>
              <Fade distance="10%" bottom>
                <Heading type="h4" color="white" bold className={styles.heading}>
                  {suCaseStudy.dataManagementTitle}
                  <Text color="suPink" className={styles.dot}>
                    .
                  </Text>
                </Heading>
              </Fade>
              <Fade distance="10%" delay={200} bottom>
                <Text className={styles.description} color="white" splitParagraphs>
                  {suCaseStudy.dataManagementDescription.internal.content}
                </Text>
              </Fade>
            </Col>

            <Col xs={12} sm={12} md={6} lg={6} className={styles.dataManagementImageWrapper}>
              <Fade distance="10%" delay={350} bottom>
                <Image
                  {...suCaseStudy.dataManagementImage.fluid}
                  alt={suCaseStudy.dataManagementImage.description}
                />
              </Fade>
            </Col>
          </Row>
        </Container>

        <ViewportObserver
          scroll={y}
          onViewport={() => {
            setNavbarColor(variables['color-dark-gray'])
            setNavbarLight(false)
          }}
        />
      </section>

      <section className={styles.testimonial}>
        <Container className={styles.testimonialContentWrapper}>
          <Fade distance="10%" bottom>
            <Quote
              large
              text={suCaseStudy.testimonialDescription.internal.content}
              user={suCaseStudy.testimonialUser}
              userPhoto={suCaseStudy.testimonialUserImage.fluid}
              userJob={suCaseStudy.testimonialJobDescription}
              color="suPink"
              mobileComplete
            />
          </Fade>
        </Container>

        <ViewportObserver
          scroll={y}
          onViewport={() => {
            setNavbarColor(variables['color-white'])
            setNavbarLight(true)
          }}
        />
      </section>

      <section className={styles.results}>
        <div className={styles.resultsBlock}>
          <Container className={styles.resultsContentWrapper}>
            <Row>
              <Col xs={12} sm={4} md={4} lg={4} className={styles.resultsColumnWrapper}>
                <Fade distance="10%" bottom>
                  <Heading type="h4" color="white" bold>
                    {suCaseStudy.resultsTitle}
                    <Text color="suOrange" className={styles.dot}>
                      .
                    </Text>
                  </Heading>
                </Fade>
              </Col>
              <Col xs={12} sm={4} md={4} lg={4} className={styles.resultsColumnWrapper}>
                <Fade distance="10%" delay={350} bottom>
                  <Image
                    src={suCaseStudy.resultsFirstImage.file.url}
                    alt={suCaseStudy.resultsFirstImage.description}
                  />
                  <Text color="white" className={styles.resultsColumnWrapperFirstText}>
                    <span className={styles.resultsColumnWrapperAccent}>
                      {suCaseStudy.resultsFirstDescriptionAccent}
                    </span>{' '}
                    {suCaseStudy.resultsFirstDescription}
                  </Text>
                </Fade>
              </Col>
              <Col xs={12} sm={4} md={4} lg={4} className={styles.resultsColumnWrapper}>
                <Fade distance="10%" delay={450} bottom>
                  <Image
                    src={suCaseStudy.resultsSecondImage.file.url}
                    alt={suCaseStudy.resultsSecondImage.description}
                  />
                  <Text color="white" className={styles.resultsColumnWrapperSecondText}>
                    {suCaseStudy.resultsSecondDescriptionFirst}{' '}
                    <span className={styles.resultsColumnWrapperAccent}>
                      {suCaseStudy.resultsSecondDescriptionAccent}
                    </span>{' '}
                    {suCaseStudy.resultsSecondDescriptionThird}
                  </Text>
                </Fade>
              </Col>
            </Row>
          </Container>
        </div>

        <ViewportObserver
          scroll={y}
          onViewport={() => {
            setNavbarColor(variables['su-dark'])
            setNavbarLight(false)
          }}
        />
      </section>

      <section className={styles.portfolio}>
        <Fade distance="10%" bottom>
          <ProudCases currentProject="SU" caseStudies={suCaseStudy.proudCases} extendedCTA />
        </Fade>

        <ViewportObserver
          scroll={y}
          onViewport={() => {
            setNavbarColor(variables['color-white'])
            setNavbarLight(true)
          }}
        />
      </section>
    </Layout>
  )
}

export const query = graphql`
  query contentfulSuCaseStudy($locale: String) {
    contentfulSuCaseStudy(fields: { localeKey: { eq: $locale } }) {
      pageTitle
      pageDescription
      pageThumbnail {
        file {
          url
        }
      }
      heroImage {
        description
        fluid {
          ...GatsbyContentfulFluid_withWebp_noBase64
        }
      }
      briefTitle
      briefDescription {
        internal {
          content
        }
      }
      briefImage {
        description
        fluid {
          src
          srcSet
        }
      }
      ideationDescription {
        internal {
          content
        }
      }
      singleSignOnTitle
      singleSignOnDescription {
        internal {
          content
        }
      }
      singleSignOnImage {
        description
        fluid {
          src
          srcSet
        }
      }
      personalizedFeedTitle
      personalizedFeedDescription {
        internal {
          content
        }
      }
      personalizedFeedImage {
        description
        fluid {
          src
          srcSet
        }
      }
      inAppChatTitle
      inAppChatDescription {
        internal {
          content
        }
      }
      inAppChatImage {
        description
        fluid {
          src
          srcSet
        }
      }
      scalableSearchTitle
      scalableSearchDescription {
        internal {
          content
        }
      }
      scalableSearchImages {
        description
        fluid {
          src
          srcSet
        }
      }
      smartNotificationsTitle
      smartNotificationsDescription {
        internal {
          content
        }
      }
      smartNotificationsDesktopImage {
        description
        fluid(quality: 100) {
          src
          srcSet
        }
      }
      smartNotificationsMobileImage {
        description
        fluid(quality: 100) {
          src
          srcSet
        }
      }
      dataManagementTitle
      dataManagementDescription {
        internal {
          content
        }
      }
      dataManagementImage {
        description
        fluid {
          src
          srcSet
        }
      }
      testimonialUser
      testimonialJobDescription
      testimonialDescription {
        internal {
          content
        }
      }
      testimonialUserImage {
        description
        fluid {
          src
          srcSet
        }
      }
      resultsTitle
      resultsFirstDescription
      resultsFirstDescriptionAccent
      resultsFirstImage {
        description
        file {
          url
        }
      }
      resultsSecondDescriptionFirst
      resultsSecondDescriptionAccent
      resultsSecondDescriptionThird
      resultsSecondImage {
        description
        file {
          url
        }
      }
      proudCases {
        id
        title
        main
        platforms {
          internal {
            content
          }
        }
        projectColor
        projectName
        thumbnail {
          description
          fixed {
            src
            srcSet
          }
          file {
            details {
              image {
                width
                height
              }
            }
          }
        }
      }
    }
  }
`

SUCaseStudy.propTypes = {
  data: PropTypes.instanceOf(Object).isRequired,
}

export default SUCaseStudy
